import * as Handlebars from "handlebars";
import * as d3 from "./myd3";
import type { SelectionHTML } from "./AwareTypes";
import * as MixpanelAnalytics from "./MixpanelAnalytics";
import "../css/InternNetworkSeeAllStyles.scss";
import "../css/WeConnect.scss";
import {TreemapData} from "./TreemapData";
import {InternNetworkDialog} from "./InternNetworkDlog";
import {callAPI, generateCarouselItems, syncAwareTreeMapData, toggleLoadingSpinner, registerEventListener, removeAllEventListeners} from "./WeConnectUtils";
import {AppConfigs} from "./AppConfigs";

export class InternNetworkSeeAll {
	private templates?: Record<string, Handlebars.TemplateDelegate>;
	private carouselId: string;
	private p: TreemapData[];
	private DATA_INSIGHTS_PATH: string = "/aware/weconnectinterns?button_filter=4&top_n=18";
	private seeAllCards: Array<any> = []
	public currentView: string;

	public constructor(carouselId: string, p: TreemapData[]) {
		this.currentView = 'Intern Network See All'; //Added for mixpanel metrics
		this.carouselId = carouselId;
		this.p = p
	}


	public show(): void {
		const context = { carouselId: this.carouselId };
		const dialogID = "#internNetworkSeeAllDialogID";
		const dialogSel = this.showDialog(dialogID, context);
		toggleLoadingSpinner(true, null);
		let url = AppConfigs.dataInsightsApi + this.DATA_INSIGHTS_PATH
		callAPI(url+'&'+this.carouselId+'=default').then((response) => {
			this.seeAllCards = response
			generateCarouselItems(syncAwareTreeMapData(this.seeAllCards, this.p), 'see-all-', this.currentView);
		}).finally(() => {
			toggleLoadingSpinner(false, null);
			this.setupCloseButtonListener();
			this.setupBackNavClickListener();
		});
	}


	private setupBackNavClickListener(): void {
		let that = this
		const setupBackNavListener = function() {
			that.close();
			const internNetworkDialog = new InternNetworkDialog(that.p);
			internNetworkDialog.show();
		}



		const setupListener = () => {
			const backNavElement = document.getElementById('backNav');
			if (backNavElement) {
				registerEventListener(backNavElement, 'click', setupBackNavListener);
			} else {
				setTimeout(setupListener, 100);
			}
		};
		setupListener();
	}

	private showDialog(dialogID: string, context: unknown): SelectionHTML {
		if (!this.templates) this.templates = {};
		if (!this.templates[dialogID]) {
			const d3TemplateScript = d3.select(dialogID).text();
			this.templates[dialogID] = Handlebars.compile(d3TemplateScript);
		}
		const theTemplate = this.templates[dialogID];
		const body = document.querySelector("body")!;
		body.insertAdjacentHTML("beforeend", theTemplate(context));
		const lastElement = body.lastElementChild as HTMLElement;
		return d3.select<HTMLElement, unknown>(lastElement);
	}

	private setupCloseButtonListener(): void {
		let that = this
		const setupCloseBtnListener = function (event: any) {
			const target = event.target as HTMLElement;
			if (target.classList.contains('Dialog_closeButtonUpperRight')) {
				that.close();
			}
		}
		registerEventListener(document, 'click', setupCloseBtnListener);
	}

	public close(): void {
		d3.select(".AdskAlert ").remove();
		MixpanelAnalytics.trackEvent("Intern Network Closed");
		removeAllEventListeners();
	}
}
