import {UtilsHttp} from "./UtilsHttp";
import * as MixpanelAnalytics from "./MixpanelAnalytics";
import * as d3 from "./myd3";
import {TreemapData} from "./TreemapData";

const eventListeners: { element: HTMLElement | Document, type: string, listener: EventListenerOrEventListenerObject }[] = [];

export function generateCarouselItems(arr: any[], carouselId: string, currentView: string): HTMLElement | null {
	let containerId = carouselId + 'slider-inner';
	let container: HTMLElement | null = document.getElementById(containerId);
	if (container) {
	container.style.display = 'grid';
	container.innerHTML = '';
	if (arr.length === 0) return container.appendChild(generateEmptyResult(container, carouselId));
	for (let i = 0; i < arr.length; i++) {
		// this.carouselItemsUserMap.push({ id: carouselId + '' + i, user: arr[i] });
		const item = document.createElement('div');
		item.className = 'slide-img';
		item.id = carouselId + 'slide-img';
		item.innerHTML = `
	${generateCarouselCardItems(arr[i], i, carouselId)}
  `;
		if (arr[i].headshotUrl) {
			UtilsHttp.fetchImageAsBase64String(arr[i].headshotUrl)
				.then((base64String: string) => {
					d3.select(`.headshot-${carouselId}${i}`).attr('src', base64String);
				});
		}
		container.appendChild(item);

		const teamsClickListener = function() {
			MixpanelAnalytics.trackEvent("Intern Network Teams URL Clicked", { "User clicked on": arr[i].Worker_Network_ID, "View": currentView });
		};
		const slackClickListener = function() {
			MixpanelAnalytics.trackEvent("Intern Network Slack URL Clicked", { "User clicked on": arr[i].Worker_Network_ID, "View": currentView });
		};

		const teamsUrlElement = item.querySelector('.teams-url') as HTMLElement;
		const slackUrlElement = item.querySelector('.slack-url') as HTMLElement;

		registerEventListener(teamsUrlElement,'click', teamsClickListener);
		registerEventListener(slackUrlElement, 'click', slackClickListener);

		eventListeners.push({ element: teamsUrlElement, type: 'click', listener: teamsClickListener });
		eventListeners.push({ element: slackUrlElement, type: 'click', listener: slackClickListener });
	}
	}
	return container;
}

export function registerEventListener(element: HTMLElement | Document, type: string, listener: EventListenerOrEventListenerObject): void {
	element.addEventListener(type, listener);
	eventListeners.push({ element, type, listener });
}

export function removeAllEventListeners(): void {
	eventListeners.forEach(({ element, type, listener }) => {
		element.removeEventListener(type, listener);
	});
	eventListeners.length = 0;
}


function generateEmptyResult(container: HTMLElement, carouselId: string): HTMLElement {
	const item = document.createElement('div');
	item.className = 'empty-result';
	item.id = carouselId + 'empty-result';
	item.innerHTML = `
    <div class="empty-result-text">No results found</div>
  `;
	container.appendChild(item);
	return container;
}

function generateCarouselCardItems(item: any, index: number, carouselId: string): string {

	let directReportsClass = ''
	let nReportElement = ''
	let hasDirectReports = ''
	let directReportsDiv = ''
	let nContingentsElement = ''
	if(item.nReports && item.nReports.length > 0) {
		directReportsClass = `card_reports card_rowWithIcon`
		nReportElement =  `<span id="card__reports">${item.nReports}</span>`
		hasDirectReports = `<i class="tiny glyphicon glyphicon-user"></i>`
		nContingentsElement = item.nContingents && item.nContingents.length > 0 ?  `<span id="card__contingents">${item.nContingents}</span>` : ''
		directReportsDiv = `<div class=${directReportsClass}>${hasDirectReports}${nReportElement}${nContingentsElement}</div>`
	}

	let connectVia = ''
	if(true) {
		connectVia = `<div class="card_waywo connect-via-card-container">
							<div class="connect-via-title">Connect via: </div>
							<a target="_blank" href="${item.teams_url}"><div class="teams-url"></div></a>
							<a target="_blank" href="${item.slack_url}"><div class="slack-url"></div></a>
						</div>`
	}
	return `
            <div class="card intern-cards" id='${carouselId}${index}'>
			<div class="card_content">
			<div class="person-block intern-card-block">
				<div class="headshot_div" >
					<img class='headshot headshot-${carouselId}${index}' alt="       ">
					<div class="headshot_overlay" hidden>
						<div class="headshot_overlay_text">Upload Picture</div>
					</div>
				</div>
				<div class="card_nameLine">
					<div class="card_name">${item.Preferred_Name}</div>
					<div class="card_micSpeakerPronounBlock">
						<div class="pronunciation_micSpeakerLine">
						</div>
					</div>
				</div>
				<div class="card_title">
					${item.Business_Title}
				</div>
				<p class="card__map"></p>
				<div class="card_location card_rowWithIcon">
					<i class="tiny glyphicon glyphicon-globe"></i>
					<span>${item.Work_Location_City}</span>
					<span class="card_subText">${item.Work_Location_Building}</span>
				</div>
				${directReportsDiv}
				${connectVia}
			</div>
		</div>
	</div>
        `;
}

export function syncAwareTreeMapData(data: any[], p: TreemapData[]): any[] {
	let returnObj: any[] = [];
	if(data && data.length > 0) {
		data.forEach((item) => {
			const foundUser = p.find((user: any) => user.Work_Email === item.teams_user_id);
			if (foundUser) {
				returnObj.push({
					...foundUser,
					teams_url: "https://teams.microsoft.com/l/chat/0/0?users="+item.teams_user_id,
					slack_url: "slack://user?team=T02NW42JD&id="+item.slack_user_id
				});
			}
		});
	}
	return returnObj;
}

export function toggleLoadingSpinner(show: boolean, carouselId: string | null): void {
	let selector = carouselId ? `.${carouselId}lds-default` : '.lds-default';
	const spinners = document.querySelectorAll(selector) as NodeListOf<HTMLElement>;
	const carousel = document.getElementById(carouselId+'slider-inner');
	if(carousel) {
		carousel.style.setProperty('display', show ? 'none' : 'block' );
	}
	spinners.forEach(spinner => {
		spinner.style.setProperty('display', show ? 'inline-block' : 'none');
	});
}

export async function callAPI(url: string): Promise<any[]> {
	let data: any[] = [];
	try {
		const response = await fetch(url, {
			mode: 'cors',
			headers: {
				"Authorization": "Bearer " + USER_INFO.authToken,
				"Accept": "application/json"
			}
		});
		let responseText = await response.text();
		responseText = responseText.replace(/NaN/g, 'null');
		data = JSON.parse(responseText);
	} catch (error) {
		console.error("API call error:", error);
	} finally {
		return data;
	}
}
