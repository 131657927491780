import { AUTH_EVENT_LISTENER } from './Constants';

/**
 *  We have a race condition to listen to onHHComponentSuccess event from the header. Even though we have listening to event in index.html,
 *  added here for the fallback.
 */
export function fetchToken(): Promise<object> {
    return new Promise<object>((resolve) => {
            const header = document.getElementsByTagName('autodesk-header')[0];
            header ?.addEventListener(AUTH_EVENT_LISTENER, (event: CustomEvent) => {
                if(event){
                    var authToken = event.detail[0].accessToken !== null ? event.detail[0].accessToken : '';
                    let user = event.detail[0].accessToken !== null ? event.detail[0].user : '';
                    let mixpanelInstance = event.detail[0].mixpanelInstance;
                    if(authToken){
                        var userInfo = {
                            authToken: authToken,
                            user: user
                        }
                        globalThis.USER_INFO = userInfo;
                        globalThis.MIXPANEL_INSTANCE = mixpanelInstance;
                        resolve(userInfo as object);
                    }
                }
            });
    });
}
