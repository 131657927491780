import * as Handlebars from "handlebars";
import * as d3 from "./myd3";
import type { SelectionHTML } from "./AwareTypes";
import * as MixpanelAnalytics from "./MixpanelAnalytics";
import { AwareEvent, AEvent } from "./AwareEvent";
import {
	callAPI,
	generateCarouselItems,
	registerEventListener, removeAllEventListeners,
	syncAwareTreeMapData,
	toggleLoadingSpinner
} from './WeConnectUtils';
import "../css/WeConnect.scss";
import type {TreemapData} from "./TreemapData";
import {InternNetworkSeeAll} from "./InternNetworkSeeAll";
import {AppConfigs} from "./AppConfigs";


export const enum DOM {
	qModalDialog			= ".AdskAlert",
	qTextAreas				= "textarea",
}


export class InternNetworkDialog {

	private				templates?:					Record<string, Handlebars.TemplateDelegate>;

	private CAROUSEL_MAP = {
		ROLES_BASED: 'role-based-', GEO_BASED: 'geo-based-', ORG_BASED: 'org-based-'
	}

	private USERS_DATA:{roleArray: any[], geoArray: any[], orgArray: any[]} = {
		roleArray: [],
		geoArray: [],
		orgArray: []
	}

	private DATA_INSIGHTS_PATH: string = `/aware/weconnectinterns?button_filter=4&top_n=20`
	private URL_APPEND_MAP: any = {
		byRole: '&role=default',
		byGeo: '&geo=default',
		byOrg: '&org=default'
	}
	private p: TreemapData[];
	public currentView: string;


	public constructor(p: TreemapData[]) {
		this.currentView = 'Intern Network';  //Added for mixpanel metrics
		this.p = p
		this.setupCloseButtonListener();
		let url = AppConfigs.dataInsightsApi + this.DATA_INSIGHTS_PATH;
		console.log(url)
		setTimeout(() => {
			toggleLoadingSpinner(true, this.CAROUSEL_MAP.ROLES_BASED);
			callAPI(url + this.URL_APPEND_MAP.byRole)
				.then((response) => {
					if (response && response.length > 0) {
						this.USERS_DATA.roleArray = this.filterAndSliceArrays(response);
						generateCarouselItems(syncAwareTreeMapData(this.USERS_DATA.roleArray, this.p), this.CAROUSEL_MAP.ROLES_BASED,this.currentView);
					}
				})
				.finally(() => {
					toggleLoadingSpinner(false, this.CAROUSEL_MAP.ROLES_BASED);
				})
				.then(() => {
					toggleLoadingSpinner(true, this.CAROUSEL_MAP.GEO_BASED);
					return callAPI(url + this.URL_APPEND_MAP.byGeo);
				})
				.then((response) => {
					if (response && response.length > 0) {
						this.USERS_DATA.geoArray = this.filterAndSliceArrays(response, this.USERS_DATA.roleArray);
						generateCarouselItems(syncAwareTreeMapData(this.USERS_DATA.geoArray, this.p), this.CAROUSEL_MAP.GEO_BASED,this.currentView);
					}
				})
				.finally(() => {
					toggleLoadingSpinner(false, this.CAROUSEL_MAP.GEO_BASED);
				})
				.then(() => {
					toggleLoadingSpinner(true, this.CAROUSEL_MAP.ORG_BASED);
					return callAPI(url + this.URL_APPEND_MAP.byOrg);
				})
				.then((response) => {
					if (response && response.length > 0) {
						this.USERS_DATA.orgArray = this.filterAndSliceArrays(response, this.USERS_DATA.roleArray, this.USERS_DATA.geoArray);
						generateCarouselItems(syncAwareTreeMapData(this.USERS_DATA.orgArray, this.p), this.CAROUSEL_MAP.ORG_BASED,this.currentView);
					}
				})
				.finally(() => {
					toggleLoadingSpinner(false, this.CAROUSEL_MAP.ORG_BASED);
				});

			this.setupSeeAllClickListener();
		}, 400)
	}

	private setupSeeAllClickListener(): void {
		let that = this
		const setupListener = function(event: any) {
			const target = event.target as HTMLElement;
			if (target.classList.contains('see-all-link')) {
				let carouselId = target.closest('.category-title-container')?.querySelector('.category-title')?.id;
				if(carouselId) that.openSeeAllModal(carouselId);
			}
		}
		registerEventListener(document, 'click', setupListener);
	}

	private openSeeAllModal(carouselId: string): void {
		InternNetworkDialog.close(); // Close the current modal
		const seeAllModal = new InternNetworkSeeAll(carouselId, this.p);
		seeAllModal.show();
		MixpanelAnalytics.trackEvent("Intern Network See All", {'section': carouselId});
	}

	private filterAndSliceArrays(arr1: any[], arr2?: any[], arr3?: any[]): any[] {
		const existsInArray = (obj: any, array: any[]): boolean => {
			return array.some(item => JSON.stringify(item) === JSON.stringify(obj));
		};

		if (arr1 && !arr2 && !arr3) {
			return arr1.slice(0, 6);
		} else if (arr1 && arr2 && !arr3) {
			const filteredArr2 = arr1.filter(item => !existsInArray(item, arr2));
			return filteredArr2.length >= 6 ? filteredArr2.slice(0, 6) : filteredArr2;
		} else if (arr1 && arr2 && arr3) {
			let filteredArr3 = arr1.filter(item => !existsInArray(item, arr3))
			filteredArr3 = filteredArr3.filter(item => !existsInArray(item, arr2))
			return filteredArr3.length >= 6 ? filteredArr3.slice(0, 6) : filteredArr3;
		}
		return arr1
	}

	private setupCloseButtonListener(): void {
		const setupListener = function(event: any) {
			const target = event.target as HTMLElement;
			if (target.classList.contains('Dialog_closeButtonUpperRight')) {
				InternNetworkDialog.close();
				MixpanelAnalytics.trackEvent("Intern Network Closed");
			}
		}
		registerEventListener(document, 'click', setupListener);
	}

	public show(): void {
		const context = {};
		const dialogID = "#weConnectDialogID";
		const dialogSel = this.showDialog(dialogID, context);
    }

	public showDialog(dialogID: string, context: unknown): SelectionHTML {
		// Changes DOM
		if (!this.templates) this.templates = {};
		if (!this.templates[dialogID]) {
			const d3TemplateScript = d3.select(dialogID).text();
			this.templates[dialogID] = Handlebars.compile(d3TemplateScript);
		}
		const theTemplate = this.templates[dialogID];
		/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
		const body = document.querySelector("body")!;		 // Element must exists
		body.insertAdjacentHTML("beforeend", theTemplate(context));
		const lastElement = body.lastElementChild as HTMLElement;	// Works due to "beforeend"
		return d3.select<HTMLElement, unknown>(lastElement);
	}

	public static close(): void {
		d3.select(".AdskAlert ").remove();
		AwareEvent.notify(AEvent.kDialogClosed);
		removeAllEventListeners();
	}

	public generateEmptyResult(container: HTMLElement, carouselId: string): HTMLElement {
		const item = document.createElement('div');
		item.className = 'empty-result';
		item.id=carouselId+'empty-result'
		item.innerHTML = `
			<div class="empty-result-text">No results found</div>
		`;
		container.appendChild(item);
		return container

	}


}



